import React from 'react';
import '../assets/styles/components/UnderConstruction.css';

const UnderConstruction = () => {
  return (
    <>
      <div className="construction-message">
        <div className="message">
          <h1>Oops! En Construcción</h1>
          <div className='paragraphs'>
            <p>En Digipaw, nos apasiona transformar el cuidado de las mascotas mediante una gestión colaborativa. Creemos firmemente que, trabajando juntos, podemos mejorar la calidad de vida de nuestros amigos peludos (¡y no tan peludos!), eliminar el sufrimiento animal y promover un trato compasivo.</p>

            <p> Nuestra misión es hacer del cuidado de las mascotas una experiencia divertida y cooperativa. Queremos que todos los involucrados colaboren para garantizar que las mascotas sean valoradas y cuidadas como se merecen.</p>

            <p>Con Digipaw, hagamos del mundo un lugar más compasivo para todos.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnderConstruction;
